import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Autocomplete, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import Tables from "../../../components/Table/Table";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as acts from "../../../redux/administrator/actions";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import "./OutPutFormat.scss";
import TablePagination from "@mui/material/TablePagination";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import DateRangePicker from "../../../components/DateRangePicker";
import moment from "moment";

const Index = () => {
  const listData = useSelector((state) => state?.admin?.outPutData);
  const Dispatch = useDispatch();
  const history = useHistory();
  const [pageCount, setPageCount] = useState(listData?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [outputData, setOutputData] = useState([]);
  const [page, setPage] = useState(0);
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  console.log(apiStatus)
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const [input, setInput] = useState("");
  const [fileType, setFileType] = useState("")
  const [status, setStatus] = useState("")
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");


  useEffect(() => {
    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: sDate,
      formattedEndDate: eDate,
    }
    Dispatch(
      acts.getOutPutList(data)
    );
  }, [Dispatch, rowsPerPage]);
  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setMessage(apiStatus?.message)
          setErr(true)
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (listData?.data?.length > 0) {
      setOutputData(listData?.data);
    }
  }, [listData?.data]);

  const handlePageChange = (event, value) => {
    setPage(value);
    Dispatch(
      acts.getOutPutList({
        page: value + 1,
        page_size: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setPageCount(listData?.total_count);
  };

  const newScreenNavigatin = () => {
    Dispatch(acts.getDpDetails())
    history.push("/data-point-details");
  }
  const filterByDate = (startDate, endDate) => {

    const formattedStartDate =
      startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
    const formattedEndDate =
      endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";
    setSDate(formattedStartDate);
    setEDate(formattedEndDate);



    const data = {
      page: page + 1,
      page_size: rowsPerPage,
      fileType,
      status,
      formattedStartDate: formattedStartDate,
      formattedEndDate: formattedEndDate,
    }
    Dispatch(
      acts.getOutPutList(data)
    );
  };
  const clear = () => {
    // setFileType("")
    // setStatus("")
    // Dispatch(
    //   acts.getDeletedData({
    //     formattedStartDate: "",
    //     formattedEndDate: "",
    //   })
    // );
  }
  const handleFileType = (event) => {
    setFileType(event.target.value)
  }
  const handleStatus = (event) => {
    setStatus(event.target.value)
  }
  return (
    <>
      <Header />
      <Box className="manage-output-files">
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid className="manage-output-conatiner"
            item md={9}
            sm={8}>
            <AdministratorHeader text="Manage Output Files" outPutButton={"Data Point Details"} Click={newScreenNavigatin} />
            <Grid container className="input-wrap-container">

              <Grid item md={3} >
                <FormControl fullWidth>
                  <InputLabel
                    className="multiple-controlsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  >File Type</InputLabel>
                  <Select
                    size="small"
                    value={fileType}
                    onChange={handleFileType}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="xlsx">xlsx</MenuItem>
                    <MenuItem value="csv">csv</MenuItem>
                    <MenuItem value="csv">csv</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} className="input-wrap-container-select">
                <FormControl fullWidth >
                  <InputLabel
                    className="multiple-controlsLabel"
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name-label"
                  > Status</InputLabel>
                  <Select

                    size="small"
                    value={status}
                    onChange={handleStatus}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="xlsx">Approved</MenuItem>
                    <MenuItem value="csv">Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={5}>
                <DateRangePicker filterByDate={filterByDate} clear={clear} />
                {/* <SearchBar 
              // onSearch={(value, type) => onSearch(value, type)}
            /> */}
              </Grid>
            </Grid>
            <Box className="manage-data-list"
            >
              <Tables data={outputData} />
            </Box>
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
            <Grid
              className="pagination-wrap"
            >
              <TablePagination
                rowsPerPageOptions={[15, 25, 50, 100]}
                component="div"
                count={pageCount}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
